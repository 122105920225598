<template>
  <div v-if="dataInfoAvenants">
    <vx-card class="mb-4" title="Atlantique Prévoyance Familiale">
      <div slot="no-body">
        <vs-tabs>
          <vs-tab label="Avenants">
            <div class="">
              <div>
                <div class="clearfix mb-3">
                  <div>
                    <div class="w-auto" v-if="loadingListing">
                      <clip-loader
                        :loading="loadingListing"
                        color="primary"
                      ></clip-loader>
                    </div>
                    <div v-else>
                      <div v-if="dataInfoAvenants.length !== 0">
                        <vs-table
                          @dblSelection="avenantSelectedDouble"
                          :data="dataInfoAvenants"
                          v-model="avenantSelected"
                          search
                          stripe
                          noDataText="Aucun résultat trouvé"
                        >
                          <template slot="thead">
                            <vs-th sort-key="avenant">N° avenant</vs-th>
                            <vs-th sort-key="startDateContributions"
                              >Date d'effet</vs-th
                            >
                            <vs-th sort-key="endDateContributions"
                              >Date d'échéance</vs-th
                            >
                            <vs-th sort-key="cotisationTTC">Cotisation TTC</vs-th>
                            <vs-th sort-key="paymentStatus">Statut paiement</vs-th>
                            <vs-th>Actions</vs-th>
                          </template>
                          <template slot-scope="{ data }">
                            <vs-tr
                              :data="tr"
                              :key="indextr"
                              v-for="(tr, indextr) in data"
                            >
                              <vs-td :data="data[indextr].id">{{
                                dataInfoAvenants.length - (indextr + 1)
                              }}</vs-td>

                              <vs-td :data="data[indextr].startDateContributions">{{
                                formatDate(data[indextr].startDateContributions)
                              }}</vs-td>
                              <vs-td :data="data[indextr].endDateContributions">{{
                                formatDate(data[indextr].endDateContributions)
                              }}</vs-td>
                              <vs-td :data="data[indextr].cotisationTTC">{{
                                onFormatAmount(data[indextr].cotisationTTC)
                              }}</vs-td>
                              <vs-td :data="data[indextr].paymentStatus">{{
                                allPaymentStatus.find(
                                  el => el.id == data[indextr].paymentStatus
                                )
                                  ? allPaymentStatus.find(
                                      el => el.id == data[indextr].paymentStatus
                                    ).label
                                  : ""
                              }}</vs-td>
                              <vs-td :data="data[indextr].id">
                                <div class="inline-flex cursor-pointer">
                                  <vs-dropdown
                                    vs-trigger-click
                                    class="dd-actions cursor-pointer"
                                  >
                                    <div class="cursor-pointer">
                                      <feather-icon
                                        icon="AlignJustifyIcon"
                                        svgClasses="w-6 h-6"
                                      ></feather-icon>
                                    </div>
                                    <vs-dropdown-menu class="aab-vie-dropdown">
                                      <div>
                                        <vs-dropdown-item>
                                          <div
                                            class="ml-1 cursor-pointer"
                                            @click="getFilesList(data[indextr])"
                                          >
                                            <span class="flex items-center">
                                              <feather-icon
                                                icon="DownloadIcon"
                                                class="text-primary"
                                                svgClasses="w-6 h-6"
                                              ></feather-icon>
                                              <span class="m-3">Télécharger </span>
                                            </span>
                                          </div>
                                        </vs-dropdown-item>
                                      </div>
                                      <div>
                                        <vs-dropdown-item v-if="data[indextr].paymentStatus == 'required'">
                                          <div
                                            class="ml-1 cursor-pointer"
                                            @click="payedWithAgregator(data[indextr])"
                                          >
                                            <span class="flex items-center">
                                              <feather-icon
                                                icon="CreditCardIcon"
                                                class="text-primary"
                                                svgClasses="w-6 h-6"
                                              ></feather-icon>
                                              <span class="m-3">Payer en ligne </span>
                                            </span>
                                          </div>
                                        </vs-dropdown-item>
                                      </div>
                                    </vs-dropdown-menu>
                                  </vs-dropdown>
                                </div>
                              </vs-td> 
                            </vs-tr>
                          </template>
                        </vs-table>
                      </div>
                      <div v-else>
                        <div class="flex items-center justify-center mt-8">
                          <strong>Aucun enregistrement</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <vs-popup
                    class="holamundo"
                    title="Liste des fichiers"
                    :active.sync="popupListFiles"
                  >
                    <div v-if="filesList.length != 0" class="m-4">
                      <p
                        v-for="item in filesList"
                        :key="item.filename"
                        style="cursor: pointer"
                        @click="checkViewFile(item)"
                      >
                        <b
                          ><span> {{ item.name }} </span></b
                        >
                        <vs-divider />
                      </p>
                    </div>
                    <div v-else>
                      <div class="flex items-center justify-center mt-8">
                        <strong>Aucun enregistrement</strong>
                      </div>
                    </div>
                  </vs-popup>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import role from "@/config/role";
import { saveAs } from "file-saver";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "aab-vie-apf",
  components: {
    ClipLoader,
    Multiselect
  },
  props: { dataInfo: Object },
  data() {
    return {
      avenantSelected: {},
      selectedAvenant: {},
      fileToDownload: [],
      role: {},
      global: {},
      loadingListing: false,
      loading: false,
      loadingDownloadFile: false,
      productList: [],
      dataInfoAvenants: [],
       popupListFiles: false,
        filesList: [],
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Date du bordereau",
        "Nom du bordereau",
        " Type du bordereau"
      ],
      headerVal: ["index", "date", "name", "type"],
      activePrompt: false,
      //end export section
      popupActiveDownload: false,
      allStatus: [
        { id: "settle", label: "Solder" },
        { id: "pay", label: "Payer" }
      ],
      crrSlip: "",
      allBordereau: [],
      woIsAction: "",
      crrIDs: [],
      crrID: ""
    };
  },
  computed: {
    ...mapGetters("general", ["rowsTable", "allPaymentStatus"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("subscribe", ["currSuscribeData"]),
  },
  mounted() {
    this.getProject(this.dataInfo.id)

    if (!window.callbackPaymentAvenantRegistredAPF) {
      window.callbackPaymentAvenantRegistredAPF = true;
      addSuccessListener((response) => {
        console.log(response);

        if (response.transactionId) {
          this.acceptAlert({
            color: "success",
            text: "Paiement en cours de validation",
            title: "Paiement",
          })
        } else {
          this.acceptAlert({
            color: "danger",
            text: "Erreur lors du paiement",
            title: "Paiement",
          });
        }
      })
    }
  },

  methods: {
    ...mapActions("subscribe", [
      "applyGetProject",
    ]),
    async getProject(id) {
      let data = {
        projectId: id,
      }

      this.loadingListing = true;

      try {
        await this.applyGetProject(data)
        this.dataInfoAvenants = mixin.methods.filterJson(
          this.currSuscribeData.avenants,
          "createdAt"
        )

        this.loadingListing = false;
      } catch (err) {
        this.loadingListing = false;
      }
    },
    onFormatAmount(amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    clearFields() {
      (this.filesname = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    displayStatus(data) {
      const status = this.allStatus.find(val => val.id === data);
      return status.label;
    },



    getFilesList(item) {
      this.filesList = item.filesname ? item.filesname : [];
     this.popupListFiles = true;
    },

    dateElement(data) {
      return mixin.methods.dateElement(data);
    },

    showFileToDownload(data) {
      console.log("la data à observer*", data);
      this.fileToDownload = {
        filesname: data.filesname
      };

      this.popupActiveDownload = true;
    },
    avenantSelectedDouble(item) {
      if (this.checkUserRole("project_contrat").show) {
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
    downloadFile(data) {
      this.loadingDownloadFile = true;
      try {
        var FileSaver = require("file-saver");
        FileSaver.saveAs(
          `${this.$config.serverURL}/downloads/${data.filename}`,
          `${data.name}`
        );
        this.loadingDownloadFile = false;
      } catch (error) {
        this.loadingDownloadFile = false;
      }

      this.popupActiveDownload = true;
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];
        let _this = this;

        this.allSlips.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            date: _this.dateElement(el).createdAtInfo,
            name: el.name,
            type: _this.allSlipType.find(ele => ele.id == el.type).label
          };
          tableToPrintPdf.push(jsonTmp);
        });
        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName
        );
      } else {
        let tableToPrint = [];
        let _this = this;
        this.allSlips.list.forEach(el => {
          let jsonTmp = {
            date: _this.dateElement(el).createdAtInfo,
            name: el.name,
            type: _this.allSlipType.find(ele => ele.id == el.type).label
          };

          tableToPrint.push(jsonTmp);
        });

        let headerTitleTmp = [
          "Date du bordereau",
          "Nom du bordereau",
          "Type du bordereau"
        ];

        let headerValTmp = ["date", "name", "type"];

        mixin.methods.exportToData(
          [...tableToPrint],
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },

    checkViewFile(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },

    showFileData(data) {
      console.log("datatelech", data);
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    payedWithAgregator(item) {
      this.$emit("onDisplayPopup", false);
      this.selectedAvenant = item;

      openKkiapayWidget({
        amount: item.cotisationTTC,
        position: "right",
        callback: "",
        data: {
          avenantId: item.id ? item.id : "",
          paymentRequest: {
            type: "momoCard",
            data: {
              datePayment: moment(new Date()).format("D MMMM YYYY à HH:mm"),
              userType: this.user.type,
              userId: this.user.id,
              userFullname: this.user.person.firstname + " " + this.user.person.lastname,
            },
          }
        },
        theme: "#009940",
        sandbox: "true", // changer in PROD
        key: global.SANDBOXAPIKEYKKIAPAY, // change in PROD : APIKEYKKIAPAY
      });
    },
    async fetchApplyPutAvenant(paymentRequest) {
      let crr = {
        avenantId: this.selectedAvenant.id,
        transactionId: paymentRequest.data.transactionId,
        paymentRequest,
      };

      await this.applyPutAvenant(crr);
    },
  },
  watch: {
    dataInfo() {
      this.getProject(this.dataInfo.id)
    },
    popupListFiles() {
      this.$emit("onDisplayPopup", this.popupListFiles? false: true);
    },
    activePrompt() {
      this.$emit("onDisplayPopup", !this.activePrompt);
    }
  },
  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
  }
};
</script>

<style lang="scss">
.con-pagination-table {
  display: none;
}
</style>
